import './Login.scss';

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import React, {useState} from 'react';
import {Navigate} from 'react-router-dom';
import PageTextCard from '@Components/PageTextCard';
import {useAuth} from '@Hooks/AuthContext';


// eslint-disable-next-line max-lines-per-function
const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const {error, isAuthenticated, login} = useAuth();

  const handleSubmit = (event) => {
    event.preventDefault();

    login(username, password);
  };

  return (
    // eslint-disable-next-line no-negated-condition, no-nested-ternary
    !isAuthenticated ?
      <Box component="form" onSubmit={handleSubmit} className="loginForm">
        <TextField margin="normal" required fullWidth id="username" autoFocus
          label="Användarnamn" autoComplete="username" name="username"
          onChange={(event) => setUsername(event.target.value)}
        />
        <TextField margin="normal" required fullWidth id="password"
          label="Lösenord" autoComplete="current-password" name="password"
          type="password" onChange={(event) => setPassword(event.target.value)}
        />
        <Button type="submit" fullWidth variant="contained">
          Logga in
        </Button>
        <Grid container>
          {
            error &&
              <Alert severity="error" className="error">
                {error}
              </Alert>
          }
          <Grid item xs>
            <Link href="https://old.villevallapub.se/login/forgot" variant="body2">
              Glömt lösenord?
            </Link>
          </Grid>
          <Grid item>
            <Link href="/WorkHere" variant="body2">
              {'Har du inte ett konto? Sök VilleValla!'}
            </Link>
          </Grid>
        </Grid>
      </Box> :
      <CircularProgress />
  );
};

const Login = () => {
  const {isAuthenticated} = useAuth();

  const preLoginRoute = JSON.parse(sessionStorage.getItem('preLoginRoute'));
  const redirectRoute = preLoginRoute ?
    preLoginRoute :
    '/';

  return (
    <Grid item xs={12} md={6} className="loginContainer">
      <PageTextCard
        header="Logga in för att se interna sidorna"
        className="loginForm">
        <Typography variant="body1" component="p">
          ⚠️Under utveckling⚠️
        </Typography>
        {
          // eslint-disable-next-line no-negated-condition
          !isAuthenticated ?
          <LoginForm /> :
          <Navigate to={{pathname: redirectRoute,
            state: {open: true}}} />
        }
      </PageTextCard>
    </Grid>
  );
};

export default Login;
