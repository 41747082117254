import './JobbPassList.scss';
import BookedList from './BookedShifts';
import FlaggedList from './FlaggedShifts';
import FutureEvents from './FutureEvents';
import {Grid} from '@mui/material';
import PageTextCard from '@Components/PageTextCard';
import React from 'react';

const JobbPassList = () => <React.Fragment>
  <Grid item xs={12}>
    <BookedList />
  </Grid>
  <Grid item xs={12}>
    <FlaggedList />
  </Grid>
  <Grid item xs={12}>
    <PageTextCard header= "Framtida pass" elevation={3}>
      <FutureEvents/>
    </PageTextCard>
  </Grid>
</React.Fragment>;

export default JobbPassList;
