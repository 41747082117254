import api from 'App/api';

export const GET_EVENT_BEGIN = 'GET_EVENT_BEGIN';
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const GET_EVENT_FAIL = 'GET_EVENT_FAIL';

export const getEventBegin = (id) => ({
  id,
  type: GET_EVENT_BEGIN,
});

export const getEventSuccess = (payload) => ({
  payload,
  type: GET_EVENT_SUCCESS,
});

export const getEventFail = (error) => ({
  error,
  type: GET_EVENT_FAIL,
});

export const getEvent = (id) => (dispatch) => {
  dispatch(getEventBegin(id));
  api.get(`event/${id}`).
      then((res) => res.data).
      then((json) => {
        const eventsObj = json;
        dispatch(getEventSuccess(eventsObj));
      }).
      catch((err) => {
        dispatch(getEventFail(err));
      });
};
