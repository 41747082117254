import './NotFound.scss';
import {Button, Grid, Typography} from '@mui/material';
import {FormattedMessage} from 'react-intl';
import PageTextCard from 'App/Layout/Components/PageTextCard';
import React from 'react';
import {useNavigate} from 'react-router-dom';


const ErrorPage = (props) => {
  const navigate = useNavigate();
  return <Grid item xs={12}>
    <PageTextCard header={'Whoops! - 404'}>

      <Typography variant="h6" gutterBottom>
        <FormattedMessage id="notFound.content" defaultMessage="" />
      </Typography>
      <div className="navButtons">
        <Button variant="outlined"
          onClick={() => navigate('/')}>Tillbaka hem</Button>
      </div>
    </PageTextCard>

  </Grid>;
};

export default ErrorPage;
