import {Navigate, Outlet, Route, Routes} from 'react-router-dom';

import Bokning from './Views/Bokning/Bokning';
import Drinkmenu from './Views/Drinkmenu/DrinkmenuPage';
import Home from './Views/Home/Home';
import JobbPass from './Views/Pass/JobbPass/EventPage';
import JobbPassList from './Views/Pass/JobbPassList/JobbPassList';
import Kontakt from './Views/Kontakt/Kontakt';
import Login from './Views/Login/Login';
import Logout from './Views/Login/Logout';
import Matmeny from './Views/Matmeny/Matmeny';
import NotFound from './Views/NotFound/NotFound';
import OmOss from './Views/OmOss/OmOss';
import React from 'react';
import WorkHere from './Views/WorkHere/WorkHere';
import {useAuth} from './hooks/AuthContext';

const PrivateRoutes = () => {
  const {isAuthenticated} = useAuth();
  return isAuthenticated ?
    <Outlet /> :
    <Navigate to="/login" />;
};


export const externalRouteObjects = [
  {
    Component: <Home/>,
    name: 'Nav.home',
    url: '/',
  },
  {
    Component: <OmOss/>,
    headerUrl: {
      authenticated: 'https://res.cloudinary.com/hsocx4wtk/image/upload/v1708184254/IMG_8000_scaled_n9fbob.jpg',
      unauthenticated: 'https://res.cloudinary.com/hsocx4wtk/image/upload/v1708184254/IMG_8000_scaled_n9fbob.jpg',
    },
    name: 'Nav.about',
    url: '/om_oss',
  },
  {
    Component: <Bokning/>,
    headerUrl: {
      authenticated: 'https://res.cloudinary.com/hsocx4wtk/image/upload/v1708184255/IMG_7992_scaled_dofeqp.jpg',
      unauthenticated: 'https://res.cloudinary.com/hsocx4wtk/image/upload/v1708184255/IMG_7992_scaled_dofeqp.jpg',
    },
    name: 'Nav.booking',
    url: '/bokning',
  },
  {
    Component: <Kontakt/>,
    headerUrl: {
      authenticated: 'https://res.cloudinary.com/hsocx4wtk/image/upload/v1708187168/kitchenHazmat-scaled_qlnciy.jpg',
      unauthenticated: 'https://res.cloudinary.com/hsocx4wtk/image/upload/v1640304500/sofforna_z0ojcn.jpg',
    },
    name: 'Nav.contact',
    url: '/kontakt',
  },
  {
    Component: <WorkHere/>,
    headerUrl: {
      authenticated: 'https://res.cloudinary.com/hsocx4wtk/image/upload/v1708184254/kitchen_scaled_u0dat0.jpg',
      unauthenticated: 'https://res.cloudinary.com/hsocx4wtk/image/upload/v1708184254/IMG_7840_scaled_mpgwhp.jpg',
    },
    name: 'Nav.work',
    url: '/WorkHere',
  },
  {
    Component: <Matmeny/>,
    headerUrl: {
      authenticated: 'https://res.cloudinary.com/hsocx4wtk/image/upload/v1708184254/IMG_7840_scaled_mpgwhp.jpg',
      unauthenticated: 'https://res.cloudinary.com/hsocx4wtk/image/upload/v1708184254/IMG_7840_scaled_mpgwhp.jpg',
    },
    name: 'Nav.food',
    url: '/meny/mat',
  },
  {
    Component: <Drinkmenu/>,
    headerUrl: {
      authenticated: 'https://res.cloudinary.com/hsocx4wtk/image/upload/v1640304501/runt_bord_qxigll.jpg',
      unauthenticated: 'https://res.cloudinary.com/hsocx4wtk/image/upload/v1640304501/runt_bord_qxigll.jpg',
    },
    name: 'Nav.drink',
    url: '/menu/beverages',
  },
];

export const internalRouteObjects = [
  {
    Component: <JobbPassList/>,
    headerUrl: {
      authenticated: 'https://res.cloudinary.com/hsocx4wtk/image/upload/v1708508758/IMG_7882_scaled_lkws8j.jpg',
      unauthenticated: 'https://res.cloudinary.com/hsocx4wtk/image/upload/v1708508758/IMG_7882_scaled_lkws8j.jpg',
    },
    name: 'Nav.jobbpass',
    url: '/events',
  },
];

export const hiddenRouteObjects = [
  {
    Component: <Login/>,
    headerUrl: {
      authenticated: 'https://res.cloudinary.com/hsocx4wtk/image/upload/v1708184254/IMG_7840_scaled_mpgwhp.jpg',
      unauthenticated: 'https://res.cloudinary.com/hsocx4wtk/image/upload/v1708184254/IMG_7840_scaled_mpgwhp.jpg',
    },
    name: 'Login',
    url: '/login',
  },
  {
    Component: <Logout/>,
    name: 'Logout',
    url: '/logout',
  },
  {
    Component: <JobbPass/>,
    name: 'event/:id',
    url: '/event/:id',
  },
];

export const exactRouteObjects =
  [...externalRouteObjects, ...hiddenRouteObjects];

export const topLevelRouteObjects = [
  {
    Component: <NotFound/>,
    headerUrl: 'https://res.cloudinary.com/hsocx4wtk/image/upload/v1708184254/IMG_8000_scaled_n9fbob.jpg',
    name: 'NotFound',
    url: '*',
  },
];

export const allRouteObjects = [...exactRouteObjects,
  ...topLevelRouteObjects, ...internalRouteObjects];

export default class ViewRouter extends React.Component {
  // eslint-disable-next-line class-methods-use-this
  render() {
    const exactRoutes = exactRouteObjects.map(({url, Component}) => <Route
      exact
      key={url}
      path={url}
      element={Component}
    />);
    const topLevelRoutes = topLevelRouteObjects.map(({url, Component}) => <Route
      key={url}
      path={url}
      element={Component}
    />);
    const internalRoutes = internalRouteObjects.map(({url, Component}) => <Route
      exact
      key={url}
      path={url}
      element={Component}
    />);
    return (
      <>
        <Routes>
          {exactRoutes}
          {topLevelRoutes}
          <Route element={<PrivateRoutes/>}>
            {internalRoutes}
          </Route>
        </Routes>
      </>
    );
  }
}
