import ContentLayout from './ContentLayout.js';
import FooterLayout from './FooterLayout.js';
import HeaderImage from './HeaderImage';
import {LoginAlert} from './Components/LoginAlert.js';
import NavBar from './NavBar.js';
import PropTypes from 'prop-types';
import React from 'react';
import ScrollTop from './Components/ScrollTop.js';
import {useAuth} from 'App/hooks/AuthContext.js';

export default function Layout(props) {
  const {isAuthenticated} = useAuth();
  const {exactRouteObjects, navbarRouteObjects, children} = props;
  return (
    <React.Fragment>
      <NavBar routes={isAuthenticated ?
navbarRouteObjects.internal :
navbarRouteObjects.external} />
      <HeaderImage routes={exactRouteObjects} authenticated={isAuthenticated}/>
      <ContentLayout>
        {children}
      </ContentLayout>
      <FooterLayout />
      <ScrollTop />
      <LoginAlert/>
    </React.Fragment>
  );
}

Layout.propTypes = {
  children: PropTypes.object,
  exactRouteObjects: PropTypes.array,
  navbarRouteObjects: PropTypes.object,
};
