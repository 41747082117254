import './ErrorBox.scss';
import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const ErrorBox = (props) => <Box className="error-box">
  <Typography variant="body1">
    {// eslint-disable-next-line no-magic-numbers
    props.status === 403 ?
    'Oj, det där får du ju inte göra!' :
    'Oj! Något gick fel - ladda om sidan och försök igen.'}
  </Typography>
</Box>;

ErrorBox.propTypes = {
  status: PropTypes.number,
};

export default ErrorBox;
