import {
  GET_USER_SHIFTS_BEGIN,
  GET_USER_SHIFTS_FAIL,
  GET_USER_SHIFTS_SUCCESS,
} from '../Actions/user.js';

const initialusershiftsStates = {

  usershifts: {
    error: false,
    errorMsg: '',
    fetched: false,
    fetching: false,
    list: {},

  },
};

// eslint-disable-next-line
export default function userReducer(state = {...initialusershiftsStates}, action) {
  switch (action.type) {
    case GET_USER_SHIFTS_BEGIN:
      return {
        ...state,
        usershifts: {
          ...state.usershifts,
          fetched: false,
          fetching: true,
        },
      };
    case GET_USER_SHIFTS_SUCCESS:

      return {
        ...state,
        usershifts: {
          ...state.usershifts,
          error: false,
          fetched: true,
          fetching: false,
          list: action.payload,
        },
      };
    case GET_USER_SHIFTS_FAIL:
      // eslint-disable-next-line no-case-declarations
      const {usershiftsError} = action;
      return {
        ...state,
        usershifts: {
          ...state.usershifts,
          error: true,
          errorMsg: usershiftsError,
          fetched: true,
          fetching: false,
        },
      };
    default:
      return state;
  }
}
