import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import NoSpacingGrid from './NoSpacingGrid';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';


export default class CalendarEventsLayout extends React.Component {
  render() {
    const {events} = this.props;
    return <NoSpacingGrid
      spacing={1}
      left
      right
      bottom
      width="100%"
    >
      {!events || !events.list?.length ?
       <div className="calendar-item">
         <Typography variant="body1">
          Det finns inga nya event just nu, men håll
          utkik här och på våra sociala medier!
         </Typography>
       </div> :
      null}
      {events.list?.map((event) => {
        const start = event.start && event.start.date_time ?
        new Date(Date.parse(event.start.date_time)).
            toLocaleDateString('sv-SW',
                {
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  month: 'short',
                  year: 'numeric',
                }) :
null;
        return <List key={events.id}>
          <Divider component="li" />
          <ListItem>
            <div className="calendar-item">
              <Typography variant="body1">
                <b>{event.summary}</b>
              </Typography>
              <Typography variant="body1">
                {start}
              </Typography>
            </div>
          </ListItem>
        </List>;
      })}
    </NoSpacingGrid>;
  }
}


CalendarEventsLayout.propTypes = {
  events: PropTypes.object.isRequired,
};
