import api from 'App/api';


export const LOAD_FLAGGEDSHIFT_BEGIN = 'LOAD_FLAGGEDSHIFT_BEGIN';
export const LOAD_FLAGGEDSHIFT_SUCCESS = 'LOAD_FLAGGEDSHIFT_SUCCESS';
export const LOAD_FLAGGEDSHIFT_FAIL = 'LOAD_FLAGGEDSHIFT_FAIL';


export const loadFlaggedshiftsBegin = () => ({
  type: LOAD_FLAGGEDSHIFT_BEGIN,
});


export const loadFlaggedshiftsSuccess = (flaggedshifts) => ({
  flaggedshifts,
  type: LOAD_FLAGGEDSHIFT_SUCCESS,
});


export const loadFlaggedshiftsFail = (error) => ({
  error,
  type: LOAD_FLAGGEDSHIFT_FAIL,
});


export const fetchFlaggedshiftsData = () => (dispatch) => {
  dispatch(loadFlaggedshiftsBegin());
  api.get('shift/flagged_shifts').
      then((res) => res.data).
      then((json) => {
        const {shifts} = json;
        const flaggedshiftsObj = shifts;
        dispatch(loadFlaggedshiftsSuccess(flaggedshiftsObj));
      }).
      catch((err) => {
        dispatch(loadFlaggedshiftsFail(err));
      });
};
