import './JobbPassList.scss';
import {Box, Button, Divider, Grid, LinearProgress,
  Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import CircularLoader from '@Components/CircularLoader';
import ErrorBox from '@Components/ErrorBox';
import {NavLink} from 'react-router-dom';
import PageTextCard from '@Components/PageTextCard';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {fetchFlaggedshiftsData} from '@Actions/flaggedlist';
import {stringToShortDate} from '@Utils/Index.js';
import {useAuth} from '@Hooks/AuthContext';
import {useShift} from '@Hooks/shiftContext';

// eslint-disable-next-line max-lines-per-function
const FlaggedShiftComponent = (props) => {
  const {take, calls, toggleFlag} = useShift();
  const userId = parseInt(useAuth().userId, 10);
  const {userRoles} = useAuth();
  const {shift} = props;
  const [dispatch, setdispatch] = useState(calls[shift.id]);

  useEffect(() => {
    if (calls[shift.id] !== dispatch) {
      setdispatch(calls[shift.id]);
    }
  }, [calls[shift.id]]);

  return (
    <Grid container>
      {dispatch && (dispatch.loading || dispatch.error) ?
    <Box sx={{width: '100%'}}>
      {dispatch.error ?
        <ErrorBox status={dispatch.errorMsg.status}/> :
        <LinearProgress/>}
    </Box> :
    <><Grid item xs={3} className="shift-menu-cell">
      <NavLink to={`/event/${shift.event_id}`} className="navLink">
        <Typography gutterBottom color="primary"
          sx={{
            typography: {
              sm: 'body1',
              xs: 'body2',
            },
          }}>{stringToShortDate(shift.event.starts_at)}</Typography>
      </NavLink>
      <NavLink to={`/event/${shift.event_id}`} className="navLink">
        <Typography variant="body2" gutterBottom color="primary">
          {shift.event.title}
        </Typography>
      </NavLink>
    </Grid>
    <Grid item xs={3.1} className="shift-menu-cell">
      <Typography variant="body1">
        {shift.role.name}
      </Typography>
    </Grid>
    <Grid item xs={3.6} className="shift-menu-cell">
      <Typography variant="body1">
        {shift.user.firstname} {shift.user.lastname}
      </Typography>
    </Grid>
    <Grid item xs={2.2} className="shift-menu-cell" align="right">
      {shift.user_id !== userId && userRoles.
          includes(shift.role.name) &&
              <Button onClick={() => take(shift.id)}
                color="success" size="small" variant="outlined">
                ta pass
              </Button>}
      {shift.user_id === userId &&
      <Button onClick={() => toggleFlag(shift.id)} color="success" size="small"
        variant="outlined">
        avflagga
      </Button>}
    </Grid></>}
    </Grid>
  );
};

FlaggedShiftComponent.propTypes = {
  key: PropTypes.string,
  shift: PropTypes.object,
};


const FlaggedList = (props) => {
  const {calls} = useShift();
  const {shifts} = props;
  const shiftList = Object.values(shifts.list);

  useEffect(() => {
    const callsList = Array.from(Object.values(calls));
    if (callsList.length && callsList.every((call) => !call.loading)) {
      props.fetchShiftsData();
    };
  }, [calls]);

  return <>
    <PageTextCard header="Flaggade pass" elevation={3}
      style={{'overflow': 'scroll'}}>
      <CircularLoader key="loading_flagged"
        action={props.fetchShiftsData} reducer={shifts}>
        {shiftList.map((shift) => <React.Fragment
          key={`flag-${shift.id}`}>
          <FlaggedShiftComponent shift={shift}/>
          <Divider className="shiftDivider"/>
        </React.Fragment>)}
      </CircularLoader>
    </PageTextCard>
  </>;
};

FlaggedList.propTypes = {
  fetchShiftsData: PropTypes.func,
  shifts: PropTypes.object,
};

const mapStateToProps = (state) => ({
  fetchShiftsData: PropTypes.func,
  shifts: state.flaggedshiftsReducer.flaggedshifts,
});

const mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    fetchShiftsData: () => dispatch(fetchFlaggedshiftsData()),
  };
};

export default connect(mapStateToProps,
    mapDispatchToProps)(FlaggedList);
