import './HeaderImage.scss';
import PropTypes from 'prop-types';
import React from 'react';
import {useLocation} from 'react-router-dom';

const HeaderImage = (props) => {
  const {routes, authenticated} = props;
  const currentPath = useLocation().pathname;
  const routeObject = routes.find((obj) => obj.url === currentPath);

  let url = 'https://res.cloudinary.com/hsocx4wtk/image/upload/v1708184255/IMG_7984_scaled_sqtzog.jpg';
  if (routeObject && routeObject.headerUrl) {
    if (authenticated) {
      url = routeObject.headerUrl.authenticated;
    } else {
      url = routeObject.headerUrl.unauthenticated;
    }
  }

  return (
    <div
      className="header-image"
      style={{backgroundImage: `url("${url}")`}}
    >
    </div>
  );
};

HeaderImage.propTypes = {
  authenticated: PropTypes.bool,
  location: PropTypes.object,
  routes: PropTypes.array,
};
export default HeaderImage;
