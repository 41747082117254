import './FooterLayout.scss';

import {FormattedMessage, injectIntl} from 'react-intl';
import {Grid, Typography} from '@mui/material';

import CircularLoader from './Components/CircularLoader';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import PropTypes from 'prop-types';
import React from 'react';

import {connect} from 'react-redux';
import {fetchOpenHours} from '@Actions/openHours';

const OpeningHours = (props) => {
  const {intl, openingHours} = props;

  return (
    <div className="opening-hours-container">
      <Typography variant="h5">
        <FormattedMessage id="openingHours.hours"
          defaultMessage="Öppetider"
        />
      </Typography>
      <Typography gutterBottom variant="body1">
        {intl.formatMessage({
          defaultMessage: 'Tis',
          id: 'Day.tue.short',
        })}
        -
        {intl.formatMessage({
          defaultMessage: 'Tors',
          id: 'Day.thur.short',
        })}
        :
        {openingHours.hours.en.Tuesday}
      </Typography>
      <Typography gutterBottom variant="body1">
        {intl.formatMessage({
          defaultMessage: 'Fre',
          id: 'Day.fri.short',
        })}
        -
        {intl.formatMessage({
          defaultMessage: 'Lör',
          id: 'Day.sat.short',
        })}
        :
        {openingHours.hours.en.Friday}
      </Typography>
    </div>
  );
};

OpeningHours.propTypes = {
  intl: PropTypes.object,
  openingHours: PropTypes.object,
};

const FooterLinks = () => <Grid item xs={9} sm={9} md={3}
  className="footer-item-margin"
  order={{md: 1,
    sm: 1,
    xs: 1}}
>
  <div className="footer-item--left">
    <div className="align-center">
      <a href="/" >
        <img className="footer-logo" alt=""
          src="/images/WhiteLogo_20thAnniversary_small.png"/>
      </a>
      <div>
        <a className="icon-link" href="https://www.facebook.com/villevallapub">
          <FacebookIcon fontSize="small" />
        </a>
        <a className="icon-link" href="https://www.instagram.com/villevallapub/">
          <InstagramIcon fontSize="small" />
        </a>
      </div>
    </div>
  </div>
</Grid>;

class FooterLayout extends React.Component {
  render() {
    const {intl, openingHours, getOpenHours} = this.props;

    return (
      <div className="footer-container">
        <Grid container className="footer" spacing={1} columns={18}>
          <FooterLinks />
          <Grid item xs={9} sm={9} md={3}
            order={{md: 2,
              sm: 1,
              xs: 2}}
          >
            <CircularLoader
              reducer={openingHours}
              action={getOpenHours}
            >
              <OpeningHours intl={intl} openingHours={openingHours} />
            </CircularLoader>
          </Grid>
          <Grid item xs={9} sm={9} md={3}
            order={{md: 3,
              sm: 2,
              xs: 3}}
          >
            <div className="footer-item--left">
              <Typography variant="h5">
                Adress
              </Typography>
              <Typography gutterBottom>
                Vallavägen 4C <br />
                58215 Linköping <br />
                <a href="https://maps.google.com/?q=Vallav%C3%A4gen+4C&entry=gmail&source=g">Kartlänk</a>
              </Typography>
            </div>
          </Grid>

        </Grid>
      </div>
    );
  }
}

FooterLayout.propTypes = {
  getOpenHours: PropTypes.func,
  intl: PropTypes.object,
  openingHours: PropTypes.object,
};

const mapStateToProps = (state) => ({
  openingHours: state.openHoursReducer.openingHours,
});

const mapDispatchToProps = (dispatch) => ({
  getOpenHours: () => dispatch(fetchOpenHours()),
});

export default connect(mapStateToProps,
    mapDispatchToProps)(injectIntl(FooterLayout));
