import {Divider, Grid, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import CircularLoader from '@Components/CircularLoader';
import PageTextCard from 'App/Layout/Components/PageTextCard';
import PropTypes from 'prop-types';
import ShiftCard from '@Components/ShiftCard';
import {connect} from 'react-redux';
import {getEvent} from '@Actions/event';
import {useAuth} from 'App/hooks/AuthContext';
import {useParams} from 'react-router-dom';
import {useShift} from '@Hooks/shiftContext';


const stringToLongDate = (date) => new Date(date).toLocaleDateString('sv-SW',
    {
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      month: 'short',
    });


const JobbPass = (props) => {
  const {id} = useParams();
  const {event} = props;
  const [eventData, setEventData] = useState(event.object);
  const {calls} = useShift();
  const startDate = stringToLongDate(eventData.starts_at);
  const endDate = stringToLongDate(eventData.ends_at);
  const {isAuthenticated, userRoles, userId} = useAuth();
  useEffect(() => {
    setEventData(event.object);
    const updateEvent = () => {
      let isUpdated = false;
      const newShifts = eventData.shifts && eventData.shifts.map((shift) => {
        if (shift.id in calls && calls[shift.id].takenShift &&
          calls[shift.id].takenShift !== shift) {
          isUpdated = true;
          return calls[shift.id].takenShift;
        }
        return shift;
      });
      if (isUpdated) {
        setEventData({
          ...eventData,
          shifts: newShifts,
        });
      }
    };
    updateEvent();
  }, [calls, event]);
  return (
    isAuthenticated &&
    <CircularLoader id={id} reducer={event}
      action={() => id && props.getEvent(id)}>{eventData &&
      <Grid item xs={12}>
        <PageTextCard header={eventData.title} elevation={3}>
          <Typography variant="body1" gutterBottom >
            {eventData.descr}</Typography>
          <Divider className="shiftDivider"/>
          <Typography variant="h6" gutterBottom>
            {startDate} - {endDate}</Typography>
          <Grid spacing={2} container>
            {eventData.shifts && eventData.shifts.
                map((shift, index) => <ShiftCard key={index}
                  shift={shift} startsAt={eventData.starts_at} assignable={
                    userRoles.includes(shift.role.name) &&
                !eventData.shifts.some((sh) => sh.user_id ===
                  parseInt(userId, 10))} />)}</Grid>
        </PageTextCard></Grid>}</CircularLoader>
  );
};

JobbPass.propTypes = {
  event: PropTypes.object,
  getEvent: PropTypes.func,
};

const mapStateToProps = (state) => ({
  event: state.eventReducer.event,
});

const mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    getEvent: (id) => dispatch(getEvent(id)),
  };
};

export default connect(mapStateToProps,
    mapDispatchToProps)(JobbPass);
