

import {
  GET_EVENT_BEGIN,
  GET_EVENT_FAIL,
  GET_EVENT_SUCCESS,
} from '../Actions/event.js';

const initialeventStates = {
  event: {
    error: false,
    errorMsg: '',
    fetched: false,
    fetching: false,
    id: '',
    object: {},
  },
};


// eslint-disable-next-line default-param-last
export default function eventReducer(state = {...initialeventStates}, action) {
  switch (action.type) {
    case GET_EVENT_BEGIN:
      return {
        ...state,
        event: {
          ...state.event,
          fetched: false,
          fetching: true,
          id: action.id,
        },
      };
    case GET_EVENT_SUCCESS:

      return {
        ...state,
        event: {
          ...state.event,
          error: false,
          fetched: true,
          fetching: false,
          object: action.payload.event,
        },
      };
    case GET_EVENT_FAIL:
      // eslint-disable-next-line no-case-declarations
      const {eventError} = action;
      return {
        ...state,
        event: {
          ...state.event,
          error: true,
          errorMsg: eventError,
          fetched: true,
          fetching: false,
        },
      };
    default:
      return state;
  }
}
