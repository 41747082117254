import api from 'App/api';


export const LOAD_CALEVENTS_BEGIN = 'LOAD_CALEVENTS_BEGIN';
export const LOAD_CALEVENTS_SUCCESS = 'LOAD_CALEVENTS_SUCCESS';
export const LOAD_CALEVENTS_FAIL = 'LOAD_CALEVENTS_FAIL';


export const loadCalEventsBegin = () => ({
  type: LOAD_CALEVENTS_BEGIN,
});


export const loadCalEventsSuccess = (calEvents) => ({
  calEvents,
  type: LOAD_CALEVENTS_SUCCESS,
});


export const loadCalEventsFail = (error) => ({
  error,
  type: LOAD_CALEVENTS_FAIL,
});


export const fetchEventsData = () => (dispatch) => {
  dispatch(loadCalEventsBegin());
  api.get('external_calendar').
      then((res) => res.data).
      then((json) => {
        const events = json;
        const eventsObj = events;
        dispatch(loadCalEventsSuccess(eventsObj));
      }).
      catch((err) => {
        dispatch(loadCalEventsFail(err));
      });
};
