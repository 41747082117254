import './WIPInfoComponent.scss';
import {Box, Button, List, ListItem, Typography} from '@mui/material';
import React, {useState} from 'react';
import CardModal from '@Components/CardModal';
import {FormattedMessage} from 'react-intl';
import PageTextCard from '@Components/PageTextCard';
import PropTypes from 'prop-types';
import {useAuth} from '@Hooks/AuthContext';

const finished = [
  'Logga in',
  'Logga ut',
  'Passbokning',
  'Passlista',
  'Se interna meddelanden',
  'Unika header-bilder',
];

const inProgress = [
  'Kjamiz',
  'Personalmatrikel',
  'Och mer...',
];

const ModalComponent = (props) => <CardModal
  open={props.open} onClose={props.closeModal}>
  <Box className="status">
    <PageTextCard header={'Färdigt'}>
      <Typography variant="body1">
        <List>
          {finished.map((item, index) => <ListItem key={index}>
            {item}
          </ListItem>)}
        </List>
      </Typography>
    </PageTextCard>
    <PageTextCard header={'Pågående'}>
      <Typography variant="body1">
        <List>
          {inProgress.map((item, index) => <ListItem key={index}>
            {item}
          </ListItem>)}
        </List>
      </Typography>
    </PageTextCard>
  </Box>
</CardModal>;

ModalComponent.propTypes = {
  closeModal: PropTypes.func,
  open: PropTypes.bool,
};

const WIPInfoComponent = () => {
  const {isAuthenticated} = useAuth();
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  return (
    <React.Fragment>
      {isAuthenticated ?
        <><ModalComponent open={modalOpen}
          closeModal={handleClose}/><PageTextCard
          header="WIP: Ny intern sida"
          elevation={3}>
          <Typography>
            Kul att du kikar på nya sidan. I nuläget är det ett work in
            progress, för en lista på nuvarande funktioner och pågående
            arbete klicka på status-knappen!
          </Typography>
          <div className="WipButtons">
            <Button
              size="small"
              variant="outlined"
              onClick={handleOpen}
              sx={{marginTop: 1}}
            >Se status</Button>
            <Button
              size="small"
              variant="outlined"
              href="https://old.villevallapub.se/login/login"
              sx={{marginTop: 1}}
            >Till gamla sidan</Button>
          </div>
        </PageTextCard></> :
        <PageTextCard
          header={
            <FormattedMessage id="WIPInfo.external.header"
              defaultMessage=""/>}
          elevation={3}
        >
          <div><FormattedMessage id="WIPInfo.external.info"
            defaultMessage=""/></div>
          <Button
            variant="contained"
            href="https://old.villevallapub.se"
            sx={{marginTop: 1}}>old.villevallapub.se</Button>
        </PageTextCard>}
    </React.Fragment>
  );
};

export default WIPInfoComponent;
