import api from 'App/api';

export const GET_USER_SHIFTS_BEGIN = 'GET_USER_SHIFTS_BEGIN';
export const GET_USER_SHIFTS_SUCCESS = 'GET_USER_SHIFTS_SUCCESS';
export const GET_USER_SHIFTS_FAIL = 'GET_USER_SHIFTS_FAIL';

export const getUserShiftsBegin = () => ({
  type: GET_USER_SHIFTS_BEGIN,
});

export const getUserShiftsSuccess = (usershifts) => ({
  payload: usershifts,
  type: GET_USER_SHIFTS_SUCCESS,
});

export const getUserShiftsFail = (error) => ({
  error,
  type: GET_USER_SHIFTS_FAIL,
});

export const getUserShifts = () => (dispatch) => {
  dispatch(getUserShiftsBegin());
  api.get(`user/current/period/current/shifts`).
      then((res) => res.data).
      then((json) => {
        const {shifts} = json;
        const usershiftsObj = shifts;
        dispatch(getUserShiftsSuccess(usershiftsObj));
      }).
      catch((err) => {
        dispatch(getUserShiftsFail(err));
      });
};
