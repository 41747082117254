import api from 'App/api';

export const TAKE_SHIFT_BEGIN = 'TAKE_SHIFT_BEGIN';
export const TAKE_SHIFT_SUCCESS = 'TAKE_SHIFT_SUCCESS';
export const TAKE_SHIFT_FAIL = 'TAKE_SHIFT_FAIL';

export const TOGGLE_FLAG_SHIFT_BEGIN = 'TOGGLE_FLAG_SHIFT_BEGIN';
export const TOGGLE_FLAG_SHIFT_SUCCESS = 'TOGGLE_FLAG_SHIFT_SUCCESS';
export const TOGGLE_FLAG_SHIFT_FAIL = 'TOGGLE_FLAG_SHIFT_FAIL';


export const takeShiftBegin = (id) => ({
  id,
  type: TAKE_SHIFT_BEGIN,
});

export const takeShiftSuccess = (shift, id) => ({
  id,
  payload: shift,
  type: TAKE_SHIFT_SUCCESS,
});

export const takeShiftFail = (error, id) => ({
  error,
  id,
  type: TAKE_SHIFT_FAIL,
});

export const takeShift = (id) => (dispatch) => {
  dispatch(takeShiftBegin(id));
  api.put(`shift/${id}/take`).
      then((res) => res.data).
      then((json) => {
        const shiftObj = json;
        dispatch(takeShiftSuccess(shiftObj, id));
      }).
      catch((err) => {
        dispatch(takeShiftFail(err.response, id));
      });
};

export const toggleFlagShiftBegin = (id) => ({
  id,
  type: TOGGLE_FLAG_SHIFT_BEGIN,
});

export const toggleFlagShiftSuccess = (shift, id) => ({
  id,
  payload: shift,
  type: TOGGLE_FLAG_SHIFT_SUCCESS,
});

export const toggleFlagShiftFail = (error, id) => ({
  error,
  id,
  type: TOGGLE_FLAG_SHIFT_FAIL,
});

export const toggleFlagShift = (id) => (dispatch) => {
  dispatch(toggleFlagShiftBegin(id));
  api.put(`shift/${id}/toggle_flag`).
      then((res) => res.data).
      then((json) => {
        const shiftsObj = json;
        dispatch(toggleFlagShiftSuccess(shiftsObj, id));
      }).
      catch((err) => {
        dispatch(toggleFlagShiftFail(err.response, id));
      });
};
