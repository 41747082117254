import './JobbPassList.scss';
import {Box, Checkbox, Divider, FormControlLabel,
  FormGroup, Paper, Tab, Tabs, Typography, capitalize} from '@mui/material';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import React, {useState} from 'react';
import CircularLoader from '@Components/CircularLoader';
import EventComponent from './EventComponent';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {fetchEventsData} from '@Actions/eventlist';
import {stringToShortDate} from '@Utils/Index.js';

const getweek = (date) => {
  const jan = 0;
  const d1 = 1;
  const daysInWeek = 7;
  const numbOfMsInDay = 86400000;
  const startDate = new Date(date.getFullYear(), jan, d1);
  const days = Math.floor((date - startDate) / numbOfMsInDay);
  const weekNumber = Math.ceil(days / daysInWeek);
  return weekNumber;
};


const MonthComponent = ({date}) => <>
  <Typography className="monthTypography" color="primary" variant="h5">
    {capitalize(date)}
  </Typography>
  <Divider className="shiftDivider"/> </>;

MonthComponent.propTypes = {
  date: PropTypes.string.isRequired,
};


const WeekComponent = ({week}) => <>
  <Typography sx={{typography: {md: 'h6',
    sm: 'body2'}}}>
    Vecka {week}
  </Typography>
  <Divider className="shiftDivider"/>
</>;

WeekComponent.propTypes = {
  week: PropTypes.number.isRequired,
};


const EventDataGrid = (props) => {
  const {rows, cols} = props;
  return (
    <div className="shift-detailed">
      <DataGrid
        slots={{
          toolbar: GridToolbar,
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              col4: false,
            },
          },
        }}
        multiSelect={false}
        slotProps={{toolbar: {
          csvOptions: {disableToolbarButton: true},
          printOptions: {disableToolbarButton: true},
          quickFilterProps: {placeholder: 'Sök i passdatan'},
          showQuickFilter: true,
        }}}
        rows={rows} columns={cols}/>
    </div>
  );
};

EventDataGrid.propTypes = {
  cols: PropTypes.array,
  rows: PropTypes.array,
};

const DetailedEventList = (props) => {
  const {futureEvents} = props;
  const rows = futureEvents.map((event, index) => {
    const dateString = stringToShortDate(event.starts_at);
    return {
      col1: dateString,
      col2: event.title,
      col3: event.shifts.
          filter((shift) => !shift.user_id).
          map((shift) => shift.role.name).
          join(', '),
      col4: event.period.descr,
      id: event.id,
    };
  });
  const columns = [
    {
      field: 'col1',
      headerName: 'Datum',
      renderCell: (params) => <Link
        to={`/event/${params.row.id}`}>
        {params.row.col1}
      </Link>,
      width: 60,
    },
    {field: 'col2',
      flex: 0.3,
      headerName: 'Pass',
      minWidth: 140},
    {field: 'col3',
      flex: 2,
      headerName: 'Lediga roller',
      minWidth: 100},
    {field: 'col4',
      flex: 0.3,
      headerName: 'Period',
      minWidth: 80},
  ];

  return (
    <EventDataGrid rows={rows} cols={columns}/>
  );
};

DetailedEventList.propTypes = {
  futureEvents: PropTypes.array,
};

const Filter = ({filter, updateFilter}) => <FormGroup row key="filter-group">
  {Object.keys(filter).map((role) => <FormControlLabel
    key={role}
    label={role}
    control={
      <Checkbox
        defaultChecked={filter[role]}
        name={role}
        onChange={(event) => updateFilter(event)}
      />
    }
  />)}
</FormGroup>;
Filter.propTypes = {
  filter: PropTypes.object,
  updateFilter: PropTypes.func,
};

const EventList = (props) => {
  const {futureEvents} = props;
  const [events, setEvents] = useState(futureEvents);

  const filter = futureEvents.reduce((rolesFilter, event) => {
    event.shifts.forEach(({role: {name}}) => {
      rolesFilter[name] ||= true;
    });
    return rolesFilter;
  }, {});
  const [filterState, setfilterState] = useState(filter);
  const updateFilter = (event) => {
    const newFilter = {...filterState};
    newFilter[event.target.name] = event.target.checked;
    setfilterState(newFilter);
    if (Object.values(newFilter).every((option) => option)) {
      setEvents(futureEvents);
      return;
    }
    const filteredEvents = futureEvents.filter((workevent) =>
      workevent.shifts.some((shift) =>
        (!shift.user_id || shift.is_swappable) && newFilter[shift.role.name]));
    setEvents(filteredEvents);
  };

  let month = 0; let week = 0;
  return (
    <><Filter filter={filterState} updateFilter={updateFilter}/>
      <Paper variant="outlined" className="shiftcounter">
        <Typography>Visar {events.length} event</Typography></Paper>
      {events.map((event) => {
        const date = new Date(Date.parse(event.starts_at));
        const writeMonth = !month || date.getMonth() > month;
        const writeWeek = !week || getweek(date) > week;
        if (writeMonth) month = date.getMonth();
        if (writeWeek) week = getweek(date);

        return (
          <React.Fragment key={event.id}>
            {writeMonth && <MonthComponent
              date={date.toLocaleDateString('sv-SW', {month: 'long'})}/>}
            {writeWeek && <WeekComponent week = {week}/>}
            <EventComponent event={event}/>
            <Divider className="shiftDivider"/>
          </React.Fragment>
        );
      })}
    </>
  );
};
EventList.propTypes = {
  futureEvents: PropTypes.array,
};


class FutureEvents extends React.Component {
  state = {
    view: 'classic',
  };

  switchListView = (newValue) => {
    this.setState({view: newValue});
  };

  render() {
    const {events} = this.props;
    return (
      <>
        <CircularLoader
          action={this.props.fetchEventsData} reducer={events}>
          <Box>
            <Tabs value={this.state.view}
              onChange={(event, newValue) => this.switchListView(newValue)}>
              <Tab value="classic" label="Klassisk" />
              <Tab value="detailed" label="Detaljerad" />
            </Tabs>
          </Box>
          {this.state.view === 'classic' ?
          <EventList futureEvents = {events.list}/> :
          <DetailedEventList futureEvents = {events.list}/>}
        </CircularLoader>
      </>
    );
  }
}

FutureEvents.propTypes = {
  events: PropTypes.object,
  fetchEventsData: PropTypes.func,
};

const mapStateToProps = (state) => ({
  events: state.eventsReducer.events,
});

const mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    fetchEventsData: () => dispatch(fetchEventsData()),
  };
};

export default connect(mapStateToProps,
    mapDispatchToProps)(FutureEvents);
