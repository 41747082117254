import messagesGeneralSv from './General/sv.json';
import messagesHomeSv from './Home/sv.json';
import messagesNotFoundSv from './NotFound/sv.json';
import messagesOpeningHoursSv from './Components/OpeningHours/sv.json';
import messagesWorkhereSv from './WorkHere/sv.json';


export const messagesSv = {
  ...messagesHomeSv,
  ...messagesOpeningHoursSv,
  ...messagesGeneralSv,
  ...messagesWorkhereSv,
  ...messagesNotFoundSv,
};
