import './Matmeny.scss';

import CircularLoader from '@Components/CircularLoader';
import FoodMenyAccordion from '@Components/FoodMenyAccordion';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import {Typography} from '@mui/material';
import {connect} from 'react-redux';
import {fetchFoodMenuData} from '@Actions/menu';

const HotMenu = (props) => {
  const hotFoodList = Object.values(props.menu.menu);
  const hotFood = hotFoodList.filter((food) => food.hotOrNot);

  return hotFood.map((menuType) => <FoodMenyAccordion key={menuType.titles.sv}
    header={menuType.titles.sv} data={menuType.items} />);
};

const ColdMenu = (props) => {
  const coldFoodList = Object.values(props.menu.menu);
  const coldFood = coldFoodList.filter((food) => !food.hotOrNot);

  return coldFood.map((menuType) => <FoodMenyAccordion key={menuType.titles.sv}
    header={menuType.titles.sv} data={menuType.items} />);
};

class MatMeny extends React.Component {
  render() {
    const {menu} = this.props;

    return (
      <React.Fragment>
        <Grid container item>
          <Grid item xs={12}>
            <Typography variant="h3">
              Matmeny
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body">
              Säg till personal om du har allergier
            </Typography>
          </Grid>
        </Grid>
        <CircularLoader reducer={menu} action={this.props.getFoodMenuData}>
          <React.Fragment>
            <Grid container item xs={12} md={6}>
              <Grid item xs={12}>
                <Typography variant="h4">
                  Varmkök
                </Typography>
              </Grid>
              <HotMenu menu={menu} />
            </Grid>
            <Grid container item alignItems="flex-start" xs={12} md={6}>
              <Grid item xs={12}>
                <Typography variant="h4">
                  Kallkök
                </Typography>
              </Grid>
              <ColdMenu menu={menu} />
            </Grid>
          </React.Fragment>
        </CircularLoader>
      </React.Fragment>
    );
  }
}

MatMeny.propTypes = {
  getFoodMenuData: PropTypes.func,
  menu: PropTypes.object,
};

const mapStateToProps = (state) => ({
  menu: state.menuReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getFoodMenuData: () => dispatch(fetchFoodMenuData()),
});

export default connect(mapStateToProps,
    mapDispatchToProps)(MatMeny);
