import {
  LOAD_FLAGGEDSHIFT_BEGIN,
  LOAD_FLAGGEDSHIFT_FAIL,
  LOAD_FLAGGEDSHIFT_SUCCESS,
} from '../Actions/flaggedlist.js';

const initialFlaggedshiftsStates = {

  flaggedshifts: {
    error: false,
    errorMsg: '',
    fetched: false,
    fetching: false,
    list: {},

  },
};

// eslint-disable-next-line
export default function flaggedshiftsReducer(state = {...initialFlaggedshiftsStates}, action) {
  switch (action.type) {
    case LOAD_FLAGGEDSHIFT_BEGIN:
      return {
        ...state,
        flaggedshifts: {
          ...state.flaggedshifts,
          fetched: false,
          fetching: true,
        },
      };
    case LOAD_FLAGGEDSHIFT_SUCCESS:

      return {
        ...state,
        flaggedshifts: {
          ...state.flaggedshifts,
          error: false,
          fetched: true,
          fetching: false,
          list: action.flaggedshifts,
        },
      };
    case LOAD_FLAGGEDSHIFT_FAIL:
      // eslint-disable-next-line no-case-declarations
      const {flaggedshiftsError} = action;
      return {
        ...state,
        flaggedshifts: {
          ...state.flaggedshifts,
          error: true,
          errorMsg: flaggedshiftsError,
          fetched: true,
          fetching: false,
        },
      };
    default:
      return state;
  }
}
