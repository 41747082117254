import './JobbPassList.scss';
import {AccordionSummary, Box, Card, Collapse,
  Grid, ListItem, Paper, Typography} from '@mui/material';
import React, {useEffect} from 'react';
import CircularLoader from '@Components/CircularLoader';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getUserShifts} from '../../../Actions/user';
import {stringToShortDate} from '@Utils/Index.js';
import {useShift} from '@Hooks/shiftContext';


const ShiftComponent = (props) => {
  const {shift} = props;

  return (
    <Grid item md={4} sm={4} xs={12}>
      <Card sx={{height: '60px',
        maxWidth: {md: '280px',
          xs: '100%'}}}>
        <Grid container className="shift-menu-row">
          <Grid item md={8} sm={8} xs={7}>
            <ListItem
              className={new Date() > new Date(shift.event.starts_at) &&
                'completedShift'}
              component={Link} to={`/event/${shift.event.id}`}>
              <Box to={{pathname: '/jobbpass',
                state: {shift}}}>
                <Typography variant="body2" gutterBottom color="primary">
                  {stringToShortDate(shift.event.starts_at)}</Typography>
                <Typography variant="body1" gutterBottom color="primary">
                  {shift.event.title}
                </Typography>
              </Box>
            </ListItem>
          </Grid>
          <Grid item md={4} sm={4} xs={5}>
            <Box className="shift-menu-cell-color">
              <Typography sx={{typography: {md: 'body1',
                sm: 'body1',
                xs: 'h6'}}}>
                {shift.role.name}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

ShiftComponent.propTypes = {
  shift: PropTypes.object,
};

const BookedList = (props) => {
  const [expanded, setExpanded] = React.useState(false);
  const {calls} = useShift();
  const {shifts} = props;
  const shiftlist = shifts.list;
  useEffect(() => {
    const callsList = Array.from(Object.values(calls));
    if (callsList.length && callsList.every((call) => !call.loading)) {
      props.fetchShiftsData();
    };
  }, [calls]);

  return <Paper className="booked-card" variant="elevation" elevation={3}>
    <AccordionSummary
      onClick={() => setExpanded(!expanded)}
      expandIcon={<ExpandMoreIcon />}>
      <Typography variant="h4" color="primary">
        Mina pass
      </Typography>
    </AccordionSummary>
    <Collapse in={expanded}>
      {shiftlist.length ?
          <Box className="shift-menu-cell">
            <Grid container spacing={1}>
              {shiftlist.map((shift) => <ShiftComponent
                key={shift.id} shift={shift} />)}
            </Grid>
            <Paper variant="outlined" className="shiftcounter"
              sx={{maxWidth: '100%'}}>
              <Typography>
                Totalt {shiftlist.length} pass
              </Typography>
            </Paper>
          </Box> :
             null}
      {expanded && <CircularLoader
        action={props.fetchShiftsData} reducer={shifts}>
      </CircularLoader>}
    </Collapse>
  </Paper>;
};

BookedList.propTypes = {
  fetchShiftsData: PropTypes.func,
  shifts: PropTypes.object,
};

const mapStateToProps = (state) => ({
  fetchShiftsData: PropTypes.func,
  shifts: state.userReducer.usershifts,
});

const mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    fetchShiftsData: () => dispatch(getUserShifts()),
  };
};

export default connect(mapStateToProps,
    mapDispatchToProps)(BookedList);
