import {loginUser, logoutUser} from '@Actions/auth';
import {useDispatch, useSelector} from 'react-redux';
import {useCallback} from 'react';

export const useAuth = () => {
  const dispatch = useDispatch();

  // eslint-disable-next-line max-len
  const isAuthenticated = useSelector((state) => state.authReducer.isAuthenticated);
  const userName = useSelector((state) => state.authReducer.userName);
  const userId = useSelector((state) => state.authReducer.userId);
  const userImg = useSelector((state) => state.authReducer.userImg);
  const userRoles = useSelector((state) => state.authReducer.userRoles);
  const error = useSelector((state) => state.authReducer.error);

  const login = useCallback((username, password) => {
    dispatch(loginUser(username, password));
  }, [dispatch]);

  const logout = useCallback(() => {
    dispatch(logoutUser());
  }, [dispatch]);

  return {
    error,
    isAuthenticated,
    login,
    logout,
    userId,
    userImg,
    userName,
    userRoles,
  };
};
