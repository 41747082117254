import {takeShift, toggleFlagShift} from '@Actions/shift';
import {useDispatch, useSelector} from 'react-redux';
import {useCallback} from 'react';

export const useShift = () => {
  const dispatch = useDispatch();
  const calls = useSelector((state) => state.shiftReducer.calls);
  const futureEvents = useSelector((state) => state.eventsReducer.events.list);

  const take = useCallback((shiftId) => {
    dispatch(takeShift(shiftId));
  }, [dispatch]);

  const toggleFlag = useCallback((shiftId) => {
    dispatch(toggleFlagShift(shiftId));
  }, [dispatch]);

  return {
    calls,
    futureEvents,
    take,
    toggleFlag,
  };
};
