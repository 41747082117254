import CalendarEventsLayout from '../../Layout/Components/CalendarEventsLayout';
import CircularLoader from '../../Layout/Components/CircularLoader';
import {FormattedMessage} from 'react-intl';
import PageTextCard from '../../Layout/Components/PageTextCard';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {fetchEventsData} from '../../Actions/calendarEvents';

class CalendarComponent extends React.Component {
  render() {
    const {events} = this.props;
    return (
      <React.Fragment>
        <PageTextCard
          header={
            <FormattedMessage id="events.gcal"
              defaultMessage="Event"
            />
          }
          className="calendar-component"
          elevation={3}
        >
          <p>Alla framtida event på VilleValla Pub,
            se vår <a href="https://www.facebook.com/villevallapub">Facebook-sida</a> för mer information.</p>
          <CircularLoader
            action={this.props.fetchEventsData} reducer={events}>
            <CalendarEventsLayout events={events} />
          </CircularLoader>
        </PageTextCard>
      </React.Fragment>
    );
  }
}

CalendarComponent.propTypes = {
  events: PropTypes.object,
  fetchEventsData: PropTypes.func,
};

const mapStateToProps = (state) => ({
  events: state.calendarEventsReducer.calEvents,
});

const mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    fetchEventsData: () => dispatch(fetchEventsData()),
  };
};

export default connect(mapStateToProps,
    mapDispatchToProps)(CalendarComponent);
