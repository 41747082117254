/* eslint-disable no-magic-numbers */
import './JobbPassList.scss';
import './EventComponent.scss';
import {Box, Button, Grid, Paper, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import Collapse from '@mui/material/Collapse';
import {Link} from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import PropTypes from 'prop-types';
import ShiftCard from '@Components/ShiftCard';
import {stringToShortDate} from '@Utils/Index.js';
import {useAuth} from 'App/hooks/AuthContext';
import {useShift} from '@Hooks/shiftContext';


const CollapsedComponent = (props) => {
  const {event, expand} = props;
  const {userRoles, userId} = useAuth();

  return (
    <Collapse in={expand} >
      {
        // Only render shifts that can be seen to minimize fetching and calls.
        expand &&
      <><Box className="shift-menu-cell">
        <Grid spacing={2} container>
          {event.shifts.map((shift, index) => <ShiftCard
            key={index} shift={shift} startsAt={event.starts_at} assignable={
              userRoles.includes(shift.role.name) &&
              !event.shifts.some((sh) => sh.user_id ===
                parseInt(userId, 10))} />)}
        </Grid>
      </Box>
      <Button component={Link} className="event-link"
        size="small" variant="contained"
        to={`/event/${event.id}`}>
        Mer info
      </Button></>}
    </Collapse>
  );
};

CollapsedComponent.propTypes = {
  event: PropTypes.object.isRequired,
  expand: PropTypes.bool,
};

// eslint-disable-next-line max-lines-per-function
const EventComponent = (props) => {
  const {calls} = useShift();
  const userId = parseInt(useAuth().userId, 10);
  const [event, setEvent] = useState(props.event);
  const [expand, setExpand] = useState(false);
  useEffect(() => {
    const updateEvent = () => {
      let isUpdated = false;
      const newShifts = event.shifts.map((shift) => {
        if (shift.id in calls && calls[shift.id].takenShift &&
          calls[shift.id].takenShift !== shift) {
          isUpdated = true;
          return calls[shift.id].takenShift;
        }
        return shift;
      });
      if (isUpdated) {
        setEvent({
          ...event,
          shifts: newShifts,
        });
      }
    };

    updateEvent();
  }, [calls]);

  return <React.Fragment>
    <Paper className={event.shifts.find((shift) => shift.user_id === userId) ?
    'shift-card-assigned' :
    'shift-card'} elevation={expand ?
    2 :
    0}>
      <ListItem className="shift-menu-row" onClick={() => setExpand(!expand)}>
        <Grid container>
          <Grid item xs={1.7} className="shift-menu-cell">
            <Typography gutterBottom color="primary" className="linkTypography"
              sx={{typography: {md: 'body1',
                sm: 'body1',
                xs: 'body2'}}}>{stringToShortDate(event.starts_at)}</Typography>
          </Grid>
          <Grid item xs={3.1} className="shift-menu-cell">
            <Typography gutterBottom color="primary" className="linkTypography"
              sx={{typography: {md: 'body1',
                sm: 'body1',
                xs: 'body2'}}}>{event.title}</Typography>
          </Grid>
          <Grid item xs={4.5} className="shift-menu-cell">
            <>
              {event.shifts.filter((shift) => !shift.user_id ||
              shift.is_swappable).length > 0 &&
              <Typography
                display="inline"
                sx={{typography: {md: 'body1',
                  sm: 'body1',
                  xs: 'body2'}}}
              >
                {'Lediga pass: '}
              </Typography>
              }
              {event.shifts.
                  filter((shift) => !shift.user_id || shift.is_swappable).
                  map((shift, index, array) => <Typography key={index}
                    color={shift.is_swappable ?
                      'error' :
                      ''}
                    display="inline"
                    sx={{typography: {md: 'body1',
                      sm: 'body1',
                      xs: 'body2'}}}
                  >
                    {shift.role.name}
                    {index !== array.length - 1 && ', '}
                  </Typography>)}
            </>
          </Grid>
          <Grid item xs={2.5} className="shift-menu-cell" align="right">
            <Typography sx={{typography: {md: 'body1',
              sm: 'body1',
              xs: 'caption'}}}>{event.period.descr}</Typography>
          </Grid>
        </Grid>
      </ListItem>
      <CollapsedComponent expand={expand} event= {event}/>
    </Paper>
  </React.Fragment>;
};

EventComponent.propTypes = {
  event: PropTypes.object.isRequired,
  key: PropTypes.string,
};

export default EventComponent;
