import {
  LOAD_CALEVENTS_BEGIN,
  LOAD_CALEVENTS_FAIL,
  LOAD_CALEVENTS_SUCCESS,
} from '../Actions/calendarEvents.js';

const initialCalendarEventsStates = {

  calEvents: {
    error: false,
    errorMsg: '',
    fetched: false,
    fetching: false,
    list: {},

  },
};

// eslint-disable-next-line
  export default function calendarEventsReducer(state = {...initialCalendarEventsStates}, action) {
  switch (action.type) {
    case LOAD_CALEVENTS_BEGIN:
      return {
        ...state,
        calEvents: {
          ...state.calEvents,
          fetched: false,
          fetching: true,
        },
      };
    case LOAD_CALEVENTS_SUCCESS:

      return {
        ...state,
        calEvents: {
          ...state.calEvents,
          error: false,
          fetched: true,
          fetching: false,
          list: action.calEvents,
        },
      };
    case LOAD_CALEVENTS_FAIL:
      // eslint-disable-next-line no-case-declarations
      const {eventsError} = action;
      return {
        ...state,
        calEvents: {
          ...state.calEvents,
          error: true,
          errorMsg: eventsError,
          fetched: true,
          fetching: false,
        },
      };
    default:
      return state;
  }
}
