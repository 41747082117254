import './ShiftCard.scss';
import {Box, Button, Card, CardContent,
  Grid, LinearProgress, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import ErrorBox from '@Components/ErrorBox';
import {NavLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useAuth} from 'App/hooks/AuthContext';
import {useShift} from '@Hooks/shiftContext';

const TakeButton = ({shiftId, onClick}) => <Button
  color="success"
  sx={{
    height: {md: '100%',
      sm: '150px',
      xs: '100%'},
    minWidth: '80px',
    width: {md: '110px',
      sm: '100%'},
  }}
  onClick={() => onClick(shiftId)}
>
      Ta pass
</Button>;
TakeButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  shiftId: PropTypes.number.isRequired,
};

const OverlayButton = (props) => <Button className="flag" color={props.color}
  size="small" variant="contained"
  sx={{bottom: {md: 0,
    sm: 210,
    xs: 0},
  width: {md: '100%',
    sm: '100%',
    xs: '80px'}}}
  onClick={() => props.onClick(props.id)}>
  {props.children}
</Button>;
OverlayButton.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  id: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};

const UserImage = ({imageUrl}) => <Box
  sx={{
    height: '100%',
    maxHeight: '150px',
    overflow: 'hidden',
    width: {md: '110px',
      sm: '100%',
      xs: '100%'},
  }}
>
  {imageUrl ?
      <img
        style={{height: '100%',
          minWidth: '80px',
          objectFit: 'cover',
          width: '100%'}}
        src={imageUrl}
        alt="User"
      /> :
      <Box sx={{height: {sm: '150px',
        xs: '100%'},
      minWidth: '80px'}} />
  }
</Box>;
UserImage.propTypes = {
  imageUrl: PropTypes.string,
};

// eslint-disable-next-line max-lines-per-function
const ShiftCard = (props) => {
  const {toggleFlag, take, calls} = useShift();
  const {userId} = useAuth();
  const {shift, startsAt} = props;
  const futureShift = new Date(startsAt) > new Date();
  const assignable = props.assignable && futureShift;
  const [dispatch, setdispatch] = useState(calls[shift.id]);

  const shiftFlagString = () => {
    const flagCutOff = 28;
    /*
     * Not optimal that this flag/avanmäl cutoff is a constant,
     * but old page does this check in a "view"...
     */
    if (new Date(startsAt) >
    new Date().setDate(new Date().getDate() + flagCutOff)) {
      return 'Avanmäl';
    } else if (shift.is_swappable) {
      return 'Avflagga';
    }
    return 'Flagga';
  };

  useEffect(() => {
    if (calls[shift.id] !== dispatch) {
      setdispatch(calls[shift.id]);
      /*
       * Only set dispatch for loading and error here,
       * updating event is handled by other components
       * (since roles / if it's assignable etc needs to be updated)
       */
    }
  }, [calls[shift.id]]);

  const free = !shift.user_id;
  return (
    <> <Grid item md={4} sm={3} xs={12}>
      <Card sx={{display: {md: 'flex',
        sm: 'block',
        xs: 'flex'},
      height: {md: '110px',
        sm: '240px',
        xs: '80px'},
      maxWidth: '100%',
      position: 'relative'}}>
        {dispatch && (dispatch.loading || dispatch.error) ?
         <Box className="shift-no-image" sx={{height: {sm: '150px',
           xs: '100%'},
         width: '100%'}}>
           {dispatch.error ?
              <ErrorBox status={dispatch.errorMsg ?
                dispatch.errorMsg.status: dispatch.error.status}/> :
              <LinearProgress/>}</Box> :
        <>
          <Box className="shift-no-image">
            {// eslint-disable-next-line no-nested-ternary
            free ?
              assignable ?
                <TakeButton onClick={take} shiftId={shift.id} /> :
                <UserImage /> :
                <UserImage imageUrl={shift.user && shift.user.image ?
                `https://res.cloudinary.com/hsocx4wtk/image/upload/c_fill,h_100,q_75,w_100/${shift.user.image.public_id}` :
                null} />}
          </Box>
          <CardContent>
            {shift.user_id === parseInt(userId, 10) && futureShift &&
                      <OverlayButton color="error"
                        onClick={toggleFlag} id = {shift.id}>
                        {shiftFlagString(shift)}
                      </OverlayButton>
            }
            {shift.is_swappable && assignable &&
                <OverlayButton color="success" onClick={take}
                  id = {shift.id}>
                  Ta pass
                </OverlayButton>
            }
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body2">{shift.role &&
                    shift.role.name}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" >{shift.user_id && shift.user ?
                <NavLink to={`/user/${shift.user_id}`}>
                  <Typography variant="body2" gutterBottom className="userLink">
                    {shift.user.firstname} {shift.user.lastname}
                  </Typography>
                </NavLink> :
                    'Ledigt'}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </>
        }
      </Card>
    </Grid>
    </>
  );
};

ShiftCard.propTypes = {
  assignable: PropTypes.bool,
  shift: PropTypes.object,
  startsAt: PropTypes.string,
};

export default ShiftCard;
