import './Bokning.scss';

import CircularLoader from '@Components/CircularLoader';
import Divider from '@mui/material/Divider';
import FoodMenyAccordion from '@Components/FoodMenyAccordion';
import Grid from '@mui/material/Grid';
import PageTextCard from '@Components/PageTextCard';
import PropTypes from 'prop-types';
import React from 'react';
import {Typography} from '@mui/material';

import {connect} from 'react-redux';
import {fetchBookingFoodMenuData} from '@Actions/booking';
import {fetchContactPersons} from '@Actions/contactPersons';

const BookingMenu = (props) => {
  const {bookingMenu} = props;

  const bookingMenuId = 1;

  const menuAccordion = (menu) => <React.Fragment>
    <FoodMenyAccordion
      key={1}
      header="Bokningsmat"
      data={menu.items}
      hideHeader={true}
      hidePrice={true}
    />
  </React.Fragment>;

  return menuAccordion(bookingMenu[bookingMenuId]);
};
const BokningsInformation = (props) => <Grid item >
  <Typography variant="body1">
    Vi erbjuder två olika bokningsalternativ:
    <i> Pubkvällar</i> där ni har källaren ni kan umgås hela kvällen, samt
    <i> provningar</i> som vi håller i. För mer information, se nedan. Är
    ni intresserade av att anordna en kväll hos oss eller har frågor/idéer
    så tveka inte att höra av er till oss.
  </Typography>
  <br />
  <Typography variant="body1">
    {/* eslint-disable-next-line max-len */}
    {props.bookingPerson.name} - <a href={props.bookingPerson.email}>{props.bookingPerson.email}</a>
  </Typography>
  <br />
  <b> OBS! Vi anordnar inte sittningar, företagsbokningar, enstaka
    bordsreservationer eller bokningar vi anser inte medför
    studentnytta. </b>
</Grid>;

BokningsInformation.propTypes = {
  'bookingPerson': PropTypes.shape({
    'email': PropTypes.string.isRequired,
    'name': PropTypes.string.isRequired,
  }).isRequired,
};

const BokingsTyper = () => <Grid item >
  <b> Pubkväll </b>
  <Typography variant="body1">
    {/* eslint-disable-next-line max-len */}
      En Pubkväll innebär att ni har nedre våningen för ert sällskap hela kvällen. Där kan ni bland annat anordna spelkvällar, musikquiz, kick/fuck-off eller bara en trevlig hängkväll. Ni kan med fördel förbeställa mat i samband med bokningen, då kan köket förbereda maten och ni kan få den när ni kommer eller samtidigt i den mån det går.
    <ul>
      <li> Bokningen gäller för hela nedre våningen, hela kvällen (18-23). </li>
      <li> Vi erbjuder endast bokningar på tisdagar </li>
      {/* eslint-disable-next-line max-len */}
      <li> Det finns möjlighet att koppla upp en dator mot TV om det önskas. </li>
      {/* eslint-disable-next-line max-len */}
      <li> Baren i källaren/på nedre plan kommer vara bemannad men ni är självklart välkomna till den övre baren också! </li>
      {/* eslint-disable-next-line max-len */}
      <li> Det kostar 150 kr/pp och ni betalar för minst 15 personer. Detta pris inkluderar en maträtt från vår <a href="TODO: BOKNINGSMENY" >bokningsmeny</a>. </li>
      {/* eslint-disable-next-line max-len */}
      <li> Om ni endast är intresserade av att hyra lokalen kostar det 2500:- för hela kvällen. </li>
      {/* eslint-disable-next-line max-len */}
      <li> Eventuell allergilista skickas till oss två veckor innan bestämt datum. </li>
      <li> Vi reserverar oss att neka en bokning i mån av personal. </li>
    </ul>
  </Typography>
  <Divider />
  <b> Standardprovning </b>
  <Typography variant="body1">
    <ul>
      <li> Kostar 200 kr /pp, minst 15 personer. </li>
      <li> Håller på i ca 3 timmar. </li>
      <li> Provningar anordnar vi på tisdagar och lördagar. </li>
    </ul>
  </Typography>
  <Divider />
  <b> Specialprovning </b>
  <Typography variant="body1">
    {/* eslint-disable-next-line max-len */}
    Precis som namnet antyder är detta en provning som går på djupet, för den som vill veta lite mera och vill ha det där lilla extra. Kanske en ekologisk provning eller en fördjupad provning av stout eller suröl? En provning av öl från ett enda land? Allt är möjligt! Priset sätts beroende på ölkostnad.
    <ul>
      <li> Kostar &gt;200 kr /pp, minst 15 personer. </li>
      <li> Håller på i ca 3 timmar. </li>
      <li> Provningar anordnar vi på tisdagar och lördagar. </li>
    </ul>
  </Typography>
</Grid>;


const getPanels = (bookingPerson, bookingMenu) => [
  {
    'content': <BokningsInformation bookingPerson={bookingPerson} />,
    'name': 'Bokningsinformation',
    'sizing':
    {
      'md': 6,
      'sm': 12,
      'xs': 12,
    },
  },
  {
    'content':
      <Grid item >
        <BookingMenu
          bookingMenu={bookingMenu}
        />
      </Grid>,
    'name': 'Bokningsmat',
    'sizing':
    {
      'md': 6,
      'sm': 12,
      'xs': 12,
    },
  },
  {
    'content': <BokingsTyper />,
    'name': 'Bokningstyper',
    'sizing':
{
  'md': 12,
},
  },
];

const getBookingPerson = (contactPersons) => {
  const actualPersons = contactPersons.contactPersons;
  // eslint-disable-next-line max-len
  const bookingPersons = actualPersons.filter((person) => person.position === 'Bokningsansvarig');

  // eslint-disable-next-line no-magic-numbers
  return bookingPersons[0];
};


const OmOssPanels = (props) => {
  const {bookingPersons, bookingMenu} = props;

  const panels = getPanels(getBookingPerson(bookingPersons),
      bookingMenu.bookingMenu);

  // eslint-disable-next-line max-len
  const formattedPanels = panels.map((panel) => <Grid item xs={panel.sizing.xs} sm={panel.sizing.sm} md={panel.sizing.md} key={panel.name} >
    <PageTextCard spacing="2" header={panel.name}>
      <Typography variant="body1">{panel.content}</Typography>
    </PageTextCard>
  </Grid>);

  return formattedPanels;
};

class OmOss extends React.Component {
  render() {
    // eslint-disable-next-line max-len
    const {bookingMenu, contactPersons, getContactPersons, getFoodMenu} = this.props;

    return (
      <React.Fragment>
        <CircularLoader reducer={contactPersons} action={getContactPersons}>
          <CircularLoader reducer={bookingMenu} action={getFoodMenu}>
            <OmOssPanels
              bookingPersons={contactPersons}
              bookingMenu={bookingMenu}
            />
          </CircularLoader>
        </CircularLoader>
      </React.Fragment>
    );
  }
}

OmOss.propTypes = {
  bookingMenu: PropTypes.object,
  contactPersons: PropTypes.object,
  getContactPersons: PropTypes.func,
  getFoodMenu: PropTypes.func,
};

const mapStateToProps = (state) => ({
  bookingMenu: state.bookingReducer.bookingMenu,
  contactPersons: state.contactPersonsReducer.contactPersons,
});

const mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    getContactPersons: () => dispatch(fetchContactPersons()),
    getFoodMenu: () => dispatch(fetchBookingFoodMenuData()),
  };
};

export default connect(mapStateToProps,
    mapDispatchToProps)(OmOss);
