import {
  LOAD_EVENTS_BEGIN,
  LOAD_EVENTS_FAIL,
  LOAD_EVENTS_SUCCESS,
} from '../Actions/eventlist.js';

const initialEventsStates = {
  events: {
    error: false,
    errorMsg: '',
    fetched: false,
    fetching: false,
    list: {},
  },
};

// eslint-disable-next-line
export default function eventsReducer(state = {...initialEventsStates}, action) {
  switch (action.type) {
    case LOAD_EVENTS_BEGIN:
      return {
        ...state,
        events: {
          ...state.events,
          fetched: false,
          fetching: true,
        },
      };
    case LOAD_EVENTS_SUCCESS:

      return {
        ...state,
        events: {
          ...state.events,
          error: false,
          fetched: true,
          fetching: false,
          list: action.events,
        },
      };
    case LOAD_EVENTS_FAIL:
      // eslint-disable-next-line no-case-declarations
      const {eventsError} = action;
      return {
        ...state,
        events: {
          ...state.events,
          error: true,
          errorMsg: eventsError,
          fetched: true,
          fetching: false,
        },
      };
    default:
      return state;
  }
}
