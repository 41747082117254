import {
  TAKE_SHIFT_BEGIN,
  TAKE_SHIFT_FAIL,
  TAKE_SHIFT_SUCCESS,
  TOGGLE_FLAG_SHIFT_BEGIN,
  TOGGLE_FLAG_SHIFT_FAIL,
  TOGGLE_FLAG_SHIFT_SUCCESS,
} from '@Actions/shift.js';

const initialshiftState = {
  calls: {},
};

// eslint-disable-next-line default-param-last, max-lines-per-function
export default function shiftReducer(state = {...initialshiftState}, action) {
  switch (action.type) {
    case TAKE_SHIFT_BEGIN:
      return {
        ...state,
        // Store loading state for the API call
        calls: {
          ...state.calls,
          [action.id]: {
            data: null,
            error: null,
            errorMsg: null,
            loading: true,
            takenShift: null,
          },
        },
      };
    case TAKE_SHIFT_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const takeShiftSuccessObj = action.payload;
      return {
        ...state,
        // Update data for the successful API call
        calls: {
          ...state.calls,
          [action.id]: {
            data: action.payload,
            error: false,
            loading: false,
            takenShift: takeShiftSuccessObj.shift,
          },
        },
      };
    case TAKE_SHIFT_FAIL:
      return {
        ...state,
        calls: {
          ...state.calls,
          [action.id]: {
            data: null,
            error: true,
            errorMsg: action.error,
            loading: false,
          },
        },
      };

    case TOGGLE_FLAG_SHIFT_BEGIN:
      return {
        ...state,
        calls: {
          ...state.calls,
          [action.id]: {
            data: null,
            error: null,
            errorMsg: null,
            loading: true,
            takenShift: null,
          },
        },
      };
    case TOGGLE_FLAG_SHIFT_SUCCESS:
      return {
        ...state,
        calls: {
          ...state.calls,
          [action.id]: {
            data: action.payload,
            error: false,
            loading: false,
            takenShift: action.payload.shift,
          },
        },
      };
    case TOGGLE_FLAG_SHIFT_FAIL:
      return {
        ...state,
        calls: {
          ...state.calls,
          [action.id]: {
            data: null,
            error: true,
            errorMsg: action.error,
            loading: false,
          },
        },
      };
    default:
      return state;
  }
}
