import {FormattedMessage, injectIntl} from 'react-intl';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import PageTextCard from '@Components/PageTextCard';
import React from 'react';
import Typography from '@mui/material/Typography';

class WorkHere extends React.Component {
  applyToBoard = false;

  applyToWork = true;

  // eslint-disable-next-line max-lines-per-function
  render() {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={12} md={12} >
          <PageTextCard
            header={<FormattedMessage id="workHere.title" defaultMessage="" />}
            className="work-here-component" elevation={3} >
            <Typography>
              <FormattedMessage id="workHere.content" defaultMessage=""
                // eslint-disable-next-line id-length
                values={{p: (chunks) => <p> {chunks} </p>}} />
            </Typography>
          </PageTextCard>
        </Grid>

        {this.applyToWork &&
            <Grid item xs={12} sm={6} md={6} >
              <PageTextCard className="work-here-component" elevation={3}
                header={ <FormattedMessage id="workHere.application.title"
                  defaultMessage=""/> } >
                <Typography>
                  <FormattedMessage id="workHere.application.content"
                    defaultMessage=""
                    // eslint-disable-next-line id-length
                    values={{p: (chunks) => <p> {chunks} </p>}}
                  />
                </Typography>
                <Button variant="contained" color="primary" target="_blank"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSeXywV1FmEeQodqv8I1RsDruQKkSOO8n4xJE04K1q1b4mLUUA/viewform" sx={{marginTop: 1}}>
                  <FormattedMessage id="workHere.application.button"
                    defaultMessage="" />
                </Button>
              </PageTextCard>
            </Grid>}

        <Grid item xs={12} sm={6} md={6} >
          <PageTextCard
            header={<FormattedMessage id="workHere.benefits.title"
              defaultMessage=""/>}
            className="work-here-component" elevation={3} >
            <Typography>
              <FormattedMessage id="workHere.benefits.content" defaultMessage=""
                // eslint-disable-next-line id-length
                values={{p: (chunks) => <p> {chunks} </p>}} />
            </Typography>
          </PageTextCard>
        </Grid>
        {this.applyToBoard &&
            <Grid item xs={12} sm={6} md={6} >
              <PageTextCard className="work-here-component" elevation={3}
                header={ <FormattedMessage id="workHere.applyToBoard.title"
                  defaultMessage={''}/> } >
                <Typography>
                  <FormattedMessage id="workHere.applyToBoard.content"
                    defaultMessage=""
                    // eslint-disable-next-line id-length
                    values={{p: (chunks) => <p> {chunks} </p>}}
                  />
                </Typography>
                <Button variant="contained" color="primary" target="_blank"
                  href="https://www.villevallapub.se/ApplyToBoard" sx={{marginTop: 1}}>
                  <FormattedMessage id="workHere.applyToBoard.button"
                    defaultMessage="" />
                </Button>
              </PageTextCard>
            </Grid>}
      </React.Fragment>
    );
  }
}

export default injectIntl(WorkHere);
