import api from 'App/api';

export const LOGIN_BEGIN = 'LOGIN_BEGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const AUTH_ERROR = 'AUTH_ERROR';

export const LOGOUT_BEGIN = 'LOGOUT_BEGIN';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const loginBegin = () => ({
  type: LOGIN_BEGIN,
});

export const loginSuccess = (user) => ({
  payload: user,
  type: LOGIN_SUCCESS,
});

export const loginFail = (error) => ({
  payload: error,
  type: LOGIN_FAIL,
});

export const authError = (error) => ({
  payload: error,
  type: AUTH_ERROR,
});

export const loginUser = (username, password) => (dispatch) => {
  dispatch(loginBegin());
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  // eslint-disable-next-line object-property-newline, sort-keys
  const body = JSON.stringify({username, password});
  api.post('login', body, config).
      then((res) => res.data).
      then((json) => {
        if (json.access_token) {
          localStorage.setItem('accessToken', json.access_token);
          localStorage.setItem('userName', json.user);
          localStorage.setItem('userId', json.user_id);
          localStorage.setItem('userImg', json.user_public_img);
          localStorage.setItem('userRoles', json.user_roles);
          localStorage.setItem('isAuthenticated', true);
          dispatch(loginSuccess(json));
        } else {
          dispatch(loginFail(json));
        }
      }).
      catch((err) => {
        dispatch(authError(err));
      });
};

export const logoutBegin = () => ({
  type: LOGOUT_BEGIN,
});

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});

export const logoutFail = (error) => ({
  payload: error,
  type: LOGOUT_FAIL,
});

export const logoutUser = () => (dispatch) => {
  dispatch(logoutBegin());
  api.post('logout').
      then(() => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('userName');
        localStorage.removeItem('userId');
        localStorage.removeItem('userImg');
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('userRoles');
        localStorage.removeItem('token');
        dispatch(logoutSuccess());
      }).
      catch((err) => {
        dispatch(logoutFail(err));
      });
};
